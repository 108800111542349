import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import StepOne from '../Form/StepOne';
const Cotizar = () => {
  return ( 
    <div className='bg-home' id='form' style={{
      backgroundImage: 'linear-gradient(180deg, rgba(4, 2, 29, 0) 0%, #04021D 96%), url("/images/bgfont.png")', 
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      objectFit: 'fill',
      margin: 0,
      padding: 0
    }}>
<Row className="d-flex d-md align-items-center g-0"> {/* Added g-0 to remove gutters */}
  <Col md={6} className="px-5"> {/* Remove horizontal padding on md screens */}
    <h1 style={{
      fontFamily: 'Libre Franklin, sans-serif',
      fontWeight: 700,
      fontSize: '2.5rem',
      lineHeight: '1em',
      color: '#FFFFFF',
      position: 'relative',
      zIndex: 2,
      marginTop: '8rem',
    }}>
      TRANSPORTE SEGURO PARA TU <span className='t-700'>VEHÍCULO</span>
    </h1>
    <p style={{
      fontSize: '1.5rem',
      lineHeight: '1.2em',
      fontWeight: 'normal',
      color: '#FFFFFF',
      position: 'relative',
      zIndex: 2,
      marginTop: '20px',
    }}>
      ¡Compara con Nosotros y Toma Decisiones Inteligentes!
    </p>
  </Col>
  <Col md={6} className="p-5 d-none d-md-block"> {/* Remove horizontal padding on md screens */}
    <Card style={{ 
      minHeight: '500px',
      background: "rgba(2, 15, 38, 0.85)",
      backdropFilter: "blur(2px)",
      borderRadius: '12px'
      }}
      className='p-5'
      > 

     <StepOne />

    </Card>
  </Col>
</Row>

    </div>
  );
};

export default Cotizar; 