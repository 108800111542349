import { useEffect, useMemo, useState } from 'react';
import { Button, Image } from 'react-bootstrap';
import BlogCarousel from '../components/blog/BlogCarousel';
import { useBlogContext } from '../context/useBlogContext';
import '../components/blog/Blog.css';

// TODO: revisar clases de estilos
// TODO: revision general
const Blog = () => {
    const { posts, loading, error } = useBlogContext();
    const [postsCards, setPostsCards] = useState([]);
    const [disabled, setDisabled] = useState(false);

    const postsMapAll = useMemo(() => posts.map((post) => ({
        id: post.id,
        title: post.title,
        resume: post.resume,
        cardImgPath: post.cardImgPath
    })), [posts]);

    useEffect(() => {
        const postsToCards = postsMapAll.slice(0, 3);
        setPostsCards(postsToCards);
        if (postsToCards.length < 3) {
            setDisabled(true);
        }
    }, [postsMapAll]);

    if (loading) {
        return <p>Cargando...</p>;
    }
    if (error) {
        return <p>{error}</p>;
    }

    const handleShowAll = () => {
        setPostsCards(postsMapAll);
        setDisabled(true);
    };

    return (
        <>
            <Image src='/images/blog/blog_banner_1.png' id='banner' className='w-100'></Image>
            <section className='d-flex align-items-center w-100 bg-blog p-lg-5 py-lg-5 py-5 flex-column gap-5' id="blog">
                <BlogCarousel posts={postsCards}  size="large" justify="center" />
                <div className='d-flex justify-content-center'>
                    <Button style={{ backgroundColor: "#E21454", borderColor: "#E21454" }} className={` px-5 py-2 rounded-1 ` + (disabled ? 'd-none' : '')} onClick={handleShowAll} >Ver Todos</Button>
                </div>
            </section>
        </>
    );
}

export default Blog;